import React, { useState, useEffect } from 'react'
import Header from './../components/header';
import { Link, useHistory } from 'react-router-dom'
import { connectAdvanced, useDispatch } from 'react-redux';
import HeaderV2 from './../components/headerV2';
import axios from 'axios';

const baseurl = process.env.REACT_APP_FRONT_LINK;
const backendurl = process.env.REACT_APP_BACKEND_URL;

function Home() {
    const [items, setItems] = useState(["item1", "item2", "item3", "item4", "item5", "item6"])
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [showPass, setShowPass] = useState(false);
    const [data, setData] = useState({
        username: "",
        password: ""
    })


    const submitData = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setLoading(true);
        axios({
            method: "post",
            url: `${backendurl}/student/login`,
            data: data
        }).then((res) => {
            // alert(res.data.message);

            if (res.data.message === "success") {

                if (res.data.data.avatar === undefined) {
                    res.data.data.avatar = "";
                }

                localStorage.setItem("userId", res.data.data._id);
                localStorage.setItem("gondoland", res.data.data.gondoland);
                localStorage.setItem("userName", res.data.data.name);
                localStorage.setItem("userEmail", res.data.data.email);
                localStorage.setItem("userLevel", res.data.data.level);
                localStorage.setItem("userType", res.data.data.type);

                dispatch({ type: "update_user", user: res.data.data })

                history.push(`${baseurl}${res.data.data.level}/U1#1`);

                // if(res.data.data.avatar === "") {
                //     history.push(`${baseurl}`);
                // } else {

                // }
            } else {

                setTimeout(() => {
                    setErrorMsg("Invalid username or password");
                }, 1000)
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    }


    useEffect(() => {

        document.getElementsByTagName("body")[0].style.background = "#22CCF2";

    }, [])

    return (
        <>
            <div id="paints"><HeaderV2 /></div>

            <div id="mainapp" style={{ color: "#fff" }} >
                <Header />
                <div id="avatar-select">

                    <form onSubmit={submitData}>
                        <h1 align="center" style={{ textShadow: "0px 0px 10px rgba(0,0,0,0.45)", paddingBottom: 20 }}>Login Screen</h1>
                        <div className='card formstyle'>
                            <div className='form-item'><input type='text' value={data.username} onChange={(e) => setData({ ...data, username: e.target.value })} placeholder='Username' /></div>
                            <div className='form-item' style={{ position: "relative" }}>

                                <input type={showPass === true ? 'text' : 'password'} placeholder='Password' onChange={(e) => setData({ ...data, password: e.target.value })} />
                                {showPass === true && <svg onClick={() => setShowPass(false)} focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                    data-testid="VisibilityOffIcon"
                                    style={{
                                        width: 30,
                                        fill: "#22ccf2",
                                        position: "absolute",
                                        right: 18,
                                        top: 18,
                                        cursor: "pointer"
                                    }}

                                ><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path></svg>
                                }


                                {showPass === false && <svg onClick={() => setShowPass(true)} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityIcon"
                                    style={{
                                        width: 30,
                                        fill: "#22ccf2",
                                        position: "absolute",
                                        right: 18,
                                        top: 18,
                                        cursor: "pointer"
                                    }}

                                ><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
                                }

                            </div>
                            <div className='form-item' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "auto" }}>
                                <input type='submit' value='Login' />
                                {loading === true &&
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            margin: "auto",
                                            display: "block",
                                            width: "50px",
                                            height: "auto"
                                        }}
                                        width={200}
                                        height={200}
                                        viewBox="0 0 100 100"
                                        preserveAspectRatio="xMidYMid"
                                    >
                                        <circle
                                            cx={50}
                                            cy={50}
                                            fill="none"
                                            stroke="#1d0e0b"
                                            strokeWidth={10}
                                            r={35}
                                            strokeDasharray="164.93361431346415 56.97787143782138"
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                repeatCount="indefinite"
                                                dur="1s"
                                                values="0 50 50;360 50 50"
                                                keyTimes="0;1"
                                            />
                                        </circle>
                                    </svg>
                                }

                            </div>
                            {errorMsg !== "" && <div style={{
                                background: "red", color: "#fff", padding: "12px 20px", borderRadius: 20,
                                fontFamily: "gotham-bold"
                            }}>{errorMsg}</div>}

                        </div>
                    </form>
                </div>

                <audio src={baseurl + "assets/audio/click.mp3"} id="click-bt" style={{ display: "none" }} />

            </div>
        </>
    )
}
export default Home;