import React, { useEffect, useState } from 'react'
import HeaderV2 from './headerV2';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'


const baseurl = process.env.REACT_APP_FRONT_LINK;

function Header() {
    const user = useSelector((state) => state.user);
    const [url, setUrl] = useState("assets/header/logo.svg");
    const history = useHistory();

    useEffect(() => {
        if (window.location.host.search("student.migplatform.com") !== -1) {
            setUrl("assets/1.png");
        }
        let userId = localStorage.getItem("userId");
        let userLevel = localStorage.getItem("userLevel");

        if (userId !== null && userLevel !== null) {
            history.push(`${baseurl}${userLevel}/U1#1`);
        } else {
            history.push(`${baseurl}`);
        }

    }, [])

    return (<div id="header">

        <div id="header-ic">

            <div id="logo"><img style={{ maxWidth: 183, height: "auto" }} src={baseurl + url} /></div>
            <div>{user.avatar !== "" && <img style={{
                background: "#fff", borderRadius: "100%",
                maxWidth: "100%",
                height: "auto",
                padding: 3,
                boxSizing: "border-box"
            }} src={`${baseurl}assets/avatars/home/menu/item1.png`} />}</div>

        </div>

    </div>)
}
export default Header;